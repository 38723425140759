import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Element, Heading, Loader } from "react-bulma-components"
import { StaticImage } from "gatsby-plugin-image"
import { DeviceOrientationContextProvider, useDeviceOrientation } from "../contexts/device-orientation-context"

let timeOut

export const Tester = () => {
  const deviceOrientation = useDeviceOrientation()

  useEffect(
    () => {
      timeOut = window.setTimeout(() => {
        navigate('/not-supported')
      }, 3000)
      return () => window.clearTimeout(timeOut)
    },
    []
  )
  if (deviceOrientation) {
    clearTimeout(timeOut)
  }

  if (deviceOrientation) return <>Test réussi</>
  else return <><Loader /> Test en cours</>
}

const TestPage = () => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return <><Loader /></>

  return (
    <DeviceOrientationContextProvider>
      <Layout>
        <section className="section">
          <div className="container is-max-desktop">
            <Heading size={4} renderAs="h1" textTransform="uppercase">
              <Tester />
            </Heading>
          </div>
        </section>
      </Layout>
    </DeviceOrientationContextProvider>
  )
}

export default TestPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
